import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import 'tachyons'
import { MDXRenderer } from 'gatsby-plugin-mdx'


export default () => (
  <div className="pv5 pa2 flex flex-wrap mw9 center justify-around items-center">
    <StaticQuery
      query={graphql`{
  image: file(relativePath: {eq: "img/author.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
  copy: mdx(frontmatter: {name: {eq: "homepage__bio"}}) {
    body
    frontmatter {
      title
    }
  }
}
`}
      render={(data) => (
        <React.Fragment>
          <GatsbyImage
            image={data.image.childImageSharp.gatsbyImageData}
            alt="The Author"
            className="w-100 mw6" />
          <div className="w-100 pa2 mw6 mv4">
            <span className="db f2 display dark-gray">{data.copy.frontmatter.title}</span>
            {/* <div className="lh-copy f5 serif mt4" dangerouslySetInnerHTML={{__html: data.copy.html}} /> */}
            <div className="lh-copy f5 serif mt4">
              <MDXRenderer>{data.copy.body}</MDXRenderer>
            </div>
          </div>
        </React.Fragment>
    )} />
  </div>
)
